import http from './http'

/**
 * Request details of a Bewegen bike
 * @param { Object } data - The POST data
 * @param { string } data.cycle_number - The number of the bike
 * @param { string } data.traveller - The username of the user
 */

export function checkBewegenBike (data) {
  return http.post('/bikeshare/bewegen/check', data)
}

/**
 * Request an SMS with a OTP code
 * @param { Object } data - The POST data: user's phone number
 * @param { string } data.phone_dialcode - The dialcode (without a '+')
 * @param { string } data.phone_number - The phone number (without dialcode)
 */

export function requestCode (data) {
  return http.post('/bikeshare/bewegen/token', data)
}

/**
 * Submit an SMS code for validation
 * @param { Object } data - The POST data: code
 * @param { string } data.code - The SMS code
 */

export function validateCode (data) {
  return http.post('/bikeshare/bewegen/token/validate', data)
}

/**
 * Submit an SMS code for validation
 * @param { Object } data - The POST data: code
 * @param { string } data.cycle_number - The bike id
 */

export function unlockBike (data) {
  return http.post('/bikeshare/bewegen/unlock', data)
}
