<template>
  <div class="bikeshare-wrapper">
    <img src="~assets/bike-providers/hi-bike-logo.png">
    <h1>Sign Up Required</h1>
    <p>Thanks for choosing Go-Hi to book your Hi-Bike journey</p>
    <p>Unfortunately, we can only help you book a bike once you have a valid Hi-Bike account</p>
    <p>
      Before booking a bike with us, please create an account on <a target="_blank" href="https://www.hi-bike.co.uk/register">Hi-Bike's Website</a>
    </p>
  </div>
</template>

<script>
import 'styles/bikeshare.styl'

export default {
  name: 'BikeshareSignup'
}
</script>
