<template>
  <div class="bikeshare-wrapper">
    <img src="~assets/bike-providers/hi-bike-logo.png">
    <h1>{{ $t('bikeshare.authenticate.header_enter_OTP') }}</h1>
    <p>{{ $t('bikeshare.authenticate.subheader_enter_OTP') }}</p>
    <form @submit.prevent="authenticate">
      <m-multi-input class="multi-input" :label="$t('bikeshare.authenticate.label_code')" :fields="6" @input="val => code = val" />

      <div v-if="$v.code.$error">
        <div role="alert" class="text-body2 text-red">
          {{ $t('bikeshare.authenticate.validation_code') }}
        </div>
      </div>

      <div class="cta">
        <m-cta-button :label="$t('bikeshare.authenticate.cta_authenticate')" :disabled="code.length < 6" @ctaClick="authenticate" />
      </div>
    </form>
  </div>
</template>

<script>
import 'styles/bikeshare.styl'
import { MMultiInput, MCtaButton } from 'components/'
import { validateCode } from 'api/bikeshare'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'BikeshareAuthenticate',
  components: {
    MMultiInput,
    MCtaButton
  },
  data () {
    return {
      code: ''
    }
  },
  methods: {
    async authenticate () {
      const partner = this.$store.getters.partner
      this.$v.$touch()
      if (this.$v.$anyError) return
      loading.start({
        message: this.$t('loading.authenticating'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })
      try {
        await validateCode({ code: this.code })
        loading.stop()
        this.$router.push({ name: 'ondemand-bikeshare-details' })
      } catch (err) {
        if (err.data?.status === 'membership_required') {
          return this.$router.push({ name: 'ondemand-bikeshare-signup' })
        }
        handleErrors(err)
      } finally {
        loading.stop()
      }
    }
  },
  validations: {
    code: { required }
  }
}
</script>
