<template>
  <div class="bikeshare-wrapper">
    <img src="~assets/bike-providers/hi-bike-logo.png">
    <h1>
      {{ $t('bikeshare.enter_bike_id') }}
    </h1>
    <p>{{ $t('bikeshare.enter_bike_id_tooltip') }}</p>
    <form @submit.prevent="submit">
      <m-multi-input class="multi-input" :fields="5" :label="$t('bikeshare.bike_id')" @input="val => identifier = val" />
      <router-link to="/home/nearby/bikeshare">
        {{ $t('bikeshare.find_a_bike') }} >
      </router-link>
      <div class="cta">
        <m-cta-button :label="$t('bikeshare.cta_check_bike')" :disabled="identifier.length < 5" @ctaClick="submit" />
      </div>
    </form>
  </div>
</template>

<script>
import 'styles/bikeshare.styl'
import { checkBewegenBike } from 'api/bikeshare'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import { MMultiInput, MCtaButton } from 'components/'

export default {
  name: 'FindBike',
  components: { MMultiInput, MCtaButton },
  data () {
    return {
      identifier: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      partner: 'partner'
    })
  },
  methods: {
    async submit () {
      loading.start({
        message: this.$t('loading.checking.bewegen_details'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      try {
        this.$store.dispatch('bikeshare/setCycleNumber', this.identifier)
        const { data } = await checkBewegenBike({ cycle_number: this.identifier, traveller: this.user.username })
        this.$router.push({ name: 'ondemand-bikeshare-details', params: { data } })
      } catch (err) {
        if (err.data?.status) {
          if (err.data.status === 'phone_required') this.$router.push({ name: 'ondemand-bikeshare-code' })
          else this.$router.push({ name: 'ondemand-bikeshare-authenticate' })
        } else {
          handleErrors(err)
        }
      } finally {
        loading.stop()
      }
    }
  }
}
</script>
