<template>
  <div class="bikeshare-wrapper">
    <img src="~assets/bike-providers/hi-bike-logo.png">
    <h1>{{ $t('bikeshare.authenticate.header_verify_identity') }}</h1>
    <p>{{ $t('bikeshare.authenticate.subheader_verify_identity') }}</p>
    <form @submit.prevent="submit">
      <m-phone-field
        :dialcode="phone_dialcode"
        :phone="phone_number"
        :phone-error="$v.phone_dialcode.$error || $v.phone_number.$error"
        @dialcode="(val) => phone_dialcode = val"
        @phone="val => phone_number = val"
      />
      <div class="cta">
        <m-cta-button :label="$t('bikeshare.authenticate.cta_request_code')" :disabled="disableButton" @ctaClick="submit" />
      </div>
    </form>
  </div>
</template>

<script>
import 'styles/bikeshare.styl'
import { MPhoneField, MCtaButton } from 'components/'
import { requestCode } from 'api/bikeshare'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'BikeshareAuthenticate',
  components: {
    MPhoneField,
    MCtaButton
  },
  data () {
    return {
      phone_number: '',
      phone_dialcode: ''
    }
  },
  computed: {
    disableButton () {
      if (!this.phone_number.length) return true
      if (!this.phone_dialcode.length) return true
      return false
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()
      if (this.$v.$anyError) return

      // Prevents code validation error showing
      this.$v.$reset()

      const partner = this.$store.getters.partner

      loading.start({
        message: this.$t('loading.requesting.bikeshare_code'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })
      try {
        await requestCode({
          phone_dialcode: this.phone_dialcode,
          phone_number: this.phone_number
        })
        this.$router.push({ name: 'ondemand-bikeshare-authenticate' })
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
      }
    }
  },
  validations: {
    phone_dialcode: { required },
    phone_number: { required }
  }
}
</script>
