<template>
  <div v-if="status" class="bikeshare-status-wrapper">
    <div class="bikeshare-status-gradient" :style="{ '--status-color': gradientColor }" />
    <div class="bikeshare-bike-img" />
    <q-icon class="lock-icon" :name="`fas ${statusIcon}`" size="36px" :color="statusColor" />
    <q-card class="bikeshare-status-card">
      <h1>{{ $t('content_type.bikeshare') }} - <span class="text-grey-7">{{ cycleId }}</span></h1>
      <span :class="`bikeshare-status text-${statusColor}`">
        {{ $t(`bikeshare.status.${status}`) }}
      </span>
      <p class="bikeshare-status-text text-grey-8">
        {{ $t(`bikeshare.status_text.${status}`) }}
      </p>
      <m-cta-button :disabled="['unavailable', 'disconnected'].includes(status)" @ctaClick="unlock">
        {{ $t(`bikeshare.unlock_cta.${status}`) }}
      </m-cta-button>
    </q-card>
  </div>
</template>

<script>
import { unlockBike, checkBewegenBike } from 'api/bikeshare'
import { mapGetters } from 'vuex'
import store from 'store'
import loading from 'utils/loading'
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'
import { MCtaButton } from 'components/index'

export default {
  name: 'BikeshareDetailsUnlock',
  components: { MCtaButton },
  data () {
    return {
      status: null,
      cycleId: null
    }
  },
  computed: {
    ...mapGetters({
      cycleNumber: 'bikeshare/cycleNumber',
      user: 'user',
      latLng: 'geolocate/latLng'
    }),
    statusColor () {
      switch (this.status) {
      case 'startable': return 'positive'
      case 'resumable': return 'warning'
      case 'unavailable': return 'negative'
      default: return 'negative'
      }
    },
    gradientColor () {
      switch (this.status) {
      case 'startable': return 'rgba(33, 186, 69, 0.25)'
      case 'resumable': return 'rgba(242, 192, 55, 0.25)'
      case 'unavailable': return 'rgba(198, 63, 63, 0.25)'
      default: return 'rgba(198, 63, 63, 0.25)'
      }
    },
    statusIcon () {
      switch (this.status) {
      case 'startable': return 'fa-lock-open-alt'
      case 'resumable': return 'fa-pause'
      case 'unavailable': return 'fa-lock'
      default: return 'fa-unlink'
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    let status
    const partner = store.getters.partner

    // If we come straight from search, we've already done this search
    if (to.params.data) {
      status = to.params.data.connected ? to.params.data.availability : 'disconnected'
      return next(vm => {
        vm.status = status
        vm.cycleId = to.params.data.serial_number
      })
    }
    loading.start({
      partnerSlug: partner.slug,
      message: i18n.t('loading.checking.bewegen_details'),
      spinnerColor: partner.hex
    })
    try {
      const user = store.getters.user
      const cycle_number = store.getters['bikeshare/cycleNumber']
      const { data } = await checkBewegenBike({ cycle_number, traveller: user.username })
      status = data.connected ? data.availability : 'disconnected'
      next(vm => {
        vm.status = status
        vm.cycleId = data.serial_number
      })
    } catch (err) {
      handleErrors(err)
      next({ name: 'ondemand-bikeshare' })
    } finally {
      loading.stop()
    }
  },
  methods: {
    async unlock () {
      const partner = this.$store.getters.partner
      loading.start({
        message: 'Unlocking bike',
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      const { latitude, longitude } = this.latLng
      try {
        await unlockBike({ cycle_number: this.cycleNumber, traveller: this.user.username, latitude, longitude })
        this.$router.push({ name: 'ondemand-bikeshare-success', query: { success: true } })
      } catch (err) {
        handleErrors(err)
        this.$router.push({ name: 'ondemand-bikeshare-success' })
      } finally {
        loading.stop()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.bikeshare-status-wrapper
  background-image url('~assets/bike-providers/bike-map.png')
  background-size cover
  background-position center top
  height: 100%
  width 100%
  position absolute
.bikeshare-bike-img
  background-image url('~assets/bike-providers/hi-bike.png')
  background-size cover
  height: 70%
  right 0
  top 5px
  left 5%
  position absolute
  opacity 0.7
.bikeshare-status-gradient
  height: 100%
  width 110%
  position absolute
  background:
    radial-gradient(31.99% 62.44% at 58.25% 37.56%, rgba(255, 255, 255, 0.033) 0%, rgba(255, 255, 255, 0) 0.01%, rgba(255, 255, 255, 0.093) 96.63%),
    radial-gradient(101.15% 110.89% at 12.37% 8.49%, var(--status-color) 41.15%, rgba(0, 0, 0, 0) 100%)
  opacity: 0.7
.bikeshare-status-card
  display grid
  grid-template-rows 40px 30px auto 50px
  width: 94%;
  margin-inline: 3%;
  border-radius: 10px
  position: fixed;
  height: 40%;
  bottom: 64px;
  border none
  padding 10px
  text-align left
  & h1
    font-size 1.4rem
    line-height 1.4rem
  .bikeshare-status
    grid-row 2
  .bikeshare-status-text
    grid-row 3
  & button
    grid-row 4
    margin-inline 0

.lock-icon
  position absolute
  right 20px
  top 50px

</style>
