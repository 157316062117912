<template>
  <div class="success-container" :class="{ success }">
    <div v-if="success">
      <h3>{{ $t('bikeshare.outcome.success') }}</h3>
      <p>{{ $t('bikeshare.outcome.success_ipsum') }}</p>
    </div>
    <div v-else>
      <h3>{{ $t('bikeshare.outcome.failure') }}</h3>
      <p v-for="line in $t('bikeshare.outcome.failure_ipsum')" :key="line">
        {{ line }}
      </p>
      <div class="cta-container">
        <q-btn color="secondary" size="lg" :label="$t('bikeshare.outcome.help_cta')" @click="$router.push({ hash: '/travel-providers', query: { provider: 'hi-bike' } })" />
      </div>
    </div>
    <div class="cta-container">
      <q-btn color="primary" size="lg" :label="$t('bikeshare.outcome.return_cta')" @click="$router.push({ name: 'home' })" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BikeshareSuccess',
  computed: {
    success () {
      return !!this.$route.query.success
    }
  }
}
</script>
<style lang="stylus" scoped>
.success-container
  display grid
  text-align center
  gap 1rem
  grid-template-rows 1fr auto
  padding 1rem
  justify-content space-between
  place-content center
  padding-top 10vh
  height calc(100vh - 54px)
  background-image url('~assets/bike-providers/bike-map.png')
  background-size cover
  background-position center top

  &.success
    padding-top 20vh

  h4
    font-weight 800

  p
    font-size 1rem
    font-weight 400
    color lighten(black, 40%)

.cta-container
  margin-top 1.5rem
  .q-btn
    width 100%
</style>
